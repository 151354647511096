//third party dependencies
import React, { useRef, useContext, useState, useEffect } from 'react';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import styled from 'styled-components';
import { isEqual, clone, last, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button, Dropdown, Tooltip, Typography } from 'antd';
import { faAngleDown, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//our components, enums, hooks, helper functions, redux actions,...
import { features } from 'app-constants';
import { AppraisalInfoToggle } from 'components';
import { CommonLinkButton, CommonRedButton, CommonFooterContainer } from 'components/styledComponents';
import ExpandCollapseLinkButton from 'components/common/ExpandCollapseLinkButton';
import { TaskGroupsDnd } from 'components/TaskGroupsDnd';
import { RECON_PLAN } from 'app-constants/planTypes';
import { tasksActions } from 'store/tasksStore';
import { vdpActions } from 'store/vdpStore';
import { elementRotate } from 'styles/mixins';
import { useFeatures } from 'hooks';
import { DragDropContext as OurDragDropContext, HQAndProfitTimeContext } from 'utils/contexts'; //TODO: change this to be just DragDropContext when flag is removed.
import { stripReferences } from 'utils/arrayUtils';
import { getInProgressStep } from 'components/TaskGroupsDnd/helpers/functions';
import { PlanStatistics } from './PlanStatistics';
import { NEW } from '../../../../app-constants/reconPlanStatusTypes';
import { DEALER_INTERNAL_GROUP } from 'app-constants/groupTypes';

const { Text } = Typography;

const ReconPlan = React.memo(
  ({
    vehicle,
    tasks,
    closeDrawer,
    setFormProps,
    showDiscardButton,
    handleCanClose,
    scrollToId,
    isSaving,
    taskGroups,
    setTaskGroups,
    originalTaskGroups,
    planStarted,
    onTaskChange,
    showCancelButton = true, // Only show this when in tasks-only drawer, not VDP
    templateDropdownMenu,
    planTemplates,
    showCreateReconPlanFromTemplateButton,
    contentType,
    setTemplatePlanCreatedFrom,
    reconPlanFeatures,
    scrollcontainer,
    flags
  }) => {
    const tasksWithTransientTasks = useSelector((state) => state.vdp.tasksWithTransientTasks);
    const transientTasks = useSelector((state) => state.vdp.transientTasks);
    const dispatch = useDispatch();
    const [hasEditTasks, hasWorkOwnTasks, hasDealerSettings, hasLineItemsEditRole] = useFeatures(
      features.TASKS_EDIT,
      features.TASKS_WORK_OWN,
      features.DEALER_SETTINGS,
      features.LINE_ITEMS_EDIT
    );
    const { isHQ } = useContext(HQAndProfitTimeContext);
    const reconPlanBuilderContentContainer = useRef();
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [missingAssignees, toggleMissingAssignees] = useState(false);
    const [templateDropdownVisible, toggleTemplateDropdownVisible] = useState(false); //for 'create using templates' button icon animation
    const lineItemChangeStatus = useSelector((state) => state.vdp.lineItemChangeStatus);
    const vehiclesList = useSelector((state) => state.vehicles.data);
    const isInternalTech = !hasEditTasks && !hasDealerSettings && hasLineItemsEditRole;
    const enableCompleteForInternalTech = isInternalTech && hasWorkOwnTasks;
    const createTaskLabel = taskGroups.length > 0 ? 'Add Task' : 'Create Custom Plan';
    const internalGroups = useSelector((state) => state.users.assigneeData?.internalGroups);
    //#region setting dndData for task groups context provider
    //here's why we should do this and not just pass a newly created object into the contexxt provider: https://reactjs.org/docs/context.html#caveats
    const [dndData, setDndData] = useState({
      vehicleId: vehicle.id,
      planStarted,
      scrollToId,
      scrollContainer: scrollcontainer,
      setFormProps,
      onTaskChange,
      isSaving,
      vehicle,
      taskGroups,
      setTaskGroups,
      planSource: RECON_PLAN,
      contentType,
      reconPlanFeatures,
      dispatch
    });

    useEffect(() => {
      const newDndData = {
        vehicleId: vehicle.id,
        planStarted,
        scrollToId,
        scrollContainer: scrollcontainer,
        setFormProps,
        onTaskChange,
        isSaving,
        vehicle,
        taskGroups,
        setTaskGroups,
        planSource: RECON_PLAN,
        contentType,
        reconPlanFeatures,
        inProgressStep: getInProgressStep(taskGroups),
        dispatch
      };

      const copyOfDndData = clone({ ...dndData });
      const changeInDndData = !isEqual(copyOfDndData, newDndData);
      changeInDndData && setDndData(newDndData);
    }, [
      dndData,
      scrollToId,
      scrollcontainer?.current,
      showCancelButton,
      setFormProps,
      onTaskChange,
      isSaving,
      vehicle,
      taskGroups,
      setTaskGroups,
      planStarted
    ]);
    //#endregion
    useEffect(() => {
      //Do any of the tasks that have been added miss an assignee? If so, disable Save button
      let noMissingAssignees = false;
      noMissingAssignees = taskGroups.every((taskGroup) =>
        taskGroup.tasks.every(
          ({ assignedTo, assignedToName, assignedToGroupId, assignedToGroupName, assignedToGroupType }) => {
            if (!((assignedTo && assignedToName) || (assignedToGroupId && assignedToGroupName))) {
              return false;
            }

            if (!planStarted && assignedToGroupType === DEALER_INTERNAL_GROUP && assignedTo) {
              const assignedGroup = internalGroups.find((ig) => ig.id === assignedToGroupId);
              if (assignedGroup) {
                const assignedMember = assignedGroup.internalGroupUsers.find((igu) => igu.userId === assignedTo);
                if (!assignedMember) {
                  return false;
                }
              } else {
                return false;
              }
            }

            return true;
          }
        )
      );

      if (lineItemChangeStatus === LINE_ITEMS_CHANGE_STATUS.ONLY_CHANGE_LINE_ITEM) {
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(taskGroupsChanged() || !noMissingAssignees);
      }
      toggleMissingAssignees(!noMissingAssignees);
    }, [transientTasks, JSON.stringify(taskGroups), originalTaskGroups, lineItemChangeStatus]);

    const tasksWithLineItems = useSelector((state) => state.tasks?.lineItemTasks);

    const taskGroupsChanged = () => {
      /*
       * Strip the "isTransient", "isReAssigned", "prevPassThroughState", and "isAddingTask" properties from
       * taskGroups before checking equality.
       * Otherwise, if you click the Edit option in the overflow menu, edit something, and Update,
       * then Edit again, and change it all back, the Save button would stay enabled,
       * because these two props got added by TaskForm.
       * We also need to strip "taskGroupId",
       * taskGroupId is randomly generated when forming a group, and doesn't matter if the plan is identical.
       */

      const stripUnwantedEqualityProps = (fromTaskGroups) => {
        return stripReferences(fromTaskGroups).map((stg) => {
          delete stg['id'];
          return {
            ...stg,
            vehicleId: vehicle.id,
            dealerId: vehicle.dealerId,
            tasks: stg.tasks.map((t) => {
              let task = { ...t };
              [
                'isTransient',
                'isReAssigned',
                'prevPassThroughState',
                'taskGroupId',
                'isAddingTask',
                'lineItems',
                'reassignments',
                'secondsInTask',
                'taskSequence'
              ].forEach((key) => delete task[key]);
              if (task.description?.length === 0) {
                //Do not compare description key if description is empty
                delete task.description;
              }
              return task;
            })
          };
        });
      };

      const strippedTaskGroups = stripUnwantedEqualityProps(taskGroups);
      const strippedOriginalTaskGroups = stripUnwantedEqualityProps(originalTaskGroups);
      return isEqual(orderBy(strippedTaskGroups, 'sequence'), orderBy(strippedOriginalTaskGroups, 'sequence'));
    };

    const onSaveWithGroups = () => {
      const loadingMessage = planStarted ? 'Saving recon plan...' : 'Starting recon plan...';
      const successMessage = planStarted ? 'Recon plan saved!' : 'Recon plan started!';
      const errorMessage = planStarted
        ? 'An error occurred while updating the recon plan'
        : 'An error occurred while starting the recon plan';

      let newlyCompletedTasks = [];

      // Format for API
      let reconPlan = {
        dealerId: vehicle.dealerId,
        vehicleId: vehicle.id,
        taskGroups: taskGroups.map((tg) => ({
          ...tg,
          vehicleId: vehicle.id,
          tasks: tg.tasks.map((t) => {
            let taskTemp = tasksWithLineItems.items.find((taskTempItem) => taskTempItem.id === t.id);
            let lineItems = !!taskTemp ? taskTemp?.lineItems?.items : [];
            let taskId = planStarted ? t.id : null;
            if (t.isAddingTask) taskId = null;
            let task = { ...t, id: taskId };
            task.lineItems = lineItems;
            if (t.completedJustNow) {
              task.completedOn = null;
              delete task.completedJustNow;
              newlyCompletedTasks.push(task);
            }
            return task;
          })
        }))
      };

      dispatch(vdpActions.setRemoveExpandItem([]));
      //newlyCompletedTasks is only needed for analytics
      //reconPlan is what the actual plan is and that's what is sent to the API endpoint to be saved in the db
      dispatch(
        tasksActions.updateReconPlanWithGroups(
          vehicle.id,
          reconPlan,
          newlyCompletedTasks,
          loadingMessage,
          successMessage,
          errorMessage,
          vehiclesList,
          flags?.reconTimestampFilter
        )
      );
    };

    const getSaveButtonLabel = () => {
      if (planStarted) {
        return isSaving ? 'Saving' : 'Save';
      } else {
        return isSaving ? 'Starting Plan' : 'Start Plan';
      }
    };

    return (
      <div>
        <StyledContentContainer ref={reconPlanBuilderContentContainer} contentType={contentType}>
          {taskGroups.length > 0 ? <PlanStatistics vehicle={vehicle} taskGroups={taskGroups} /> : null}
          <StyledToggleContainer>
            <AppraisalInfoToggle />
            <ExpandCollapseLinkButton taskGroups={taskGroups} reconTaskCollapseFlag={flags.reconTaskCollapse} />
          </StyledToggleContainer>
          <div>
            <OurDragDropContext.Provider value={dndData}>
              <DndProvider backend={HTML5Backend}>
                <TaskGroupsDnd />
              </DndProvider>
            </OurDragDropContext.Provider>
          </div>
          <TaskCTAContainer>
            {/**If no plan, display message based on archive state and edit permissions */}
            {tasks.items.length === 0 && taskGroups.length === 0 && tasksWithTransientTasks.length === 0 && (
              <>
                <StyledNoPlanText style={{ fontSize: 24 }}>
                  <FontAwesomeIcon icon={faClipboardList} />
                </StyledNoPlanText>
                <StyledNoPlanText>No Recon Plan</StyledNoPlanText>

                {flags.reconHiddenFilter && vehicle.hidden ? (
                  <StyledNoPlanDescriptionText>
                    A plan cannot be applied to a vehicle that was deleted in Provision.
                  </StyledNoPlanDescriptionText>
                ) : vehicle.removedOn ? (
                  <StyledNoPlanDescriptionText>
                    A plan cannot be applied to an archived vehicle.
                  </StyledNoPlanDescriptionText>
                ) : vehicle.excludedOn ? (
                  <StyledNoPlanDescriptionText>
                    A plan cannot be applied to an excluded vehicle.
                  </StyledNoPlanDescriptionText>
                ) : null}
              </>
            )}
            {/** If the vehicle is not archived and not excluded and the user is allowed to edit tasks*/}
            {hasEditTasks &&
              !isHQ &&
              !vehicle.removedOn &&
              !vehicle.excludedOn &&
              (!flags.reconHiddenFilter || !vehicle.hidden) && (
                <>
                  {/** If this component is configured to show the template button*/}
                  {showCreateReconPlanFromTemplateButton && (
                    /** If there are unfinished tasks or if there are no tasks yet*/
                    /** TODO We can't use becameFlrOn yet because we don't currently clear that field if new tasks are added.
                     *  (cont.) Use becameFlrOn once we do start clearing it. */
                    <div
                      className="select-recon-plan-button"
                      style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        marginBottom:
                          vehicle.inventoryType !== NEW &&
                          (tasks.items.length !== 0 || taskGroups.length !== 0 || tasksWithTransientTasks.length !== 0)
                            ? '21px'
                            : ''
                      }}
                    >
                      {planTemplates?.data?.length > 0 ? (
                        <Dropdown
                          trigger={['click']}
                          overlay={templateDropdownMenu()}
                          onVisibleChange={(visible) => toggleTemplateDropdownVisible(visible)}
                        >
                          <TemplatePlanCTA data-template-dropdown-visible={templateDropdownVisible}>
                            Create Using Templates
                            <DropdownIcon icon={faAngleDown} size="lg" />
                          </TemplatePlanCTA>
                        </Dropdown>
                      ) : (
                        <Tooltip
                          placement="right"
                          overlayClassName="create-recon-plan-from-template-tooltip"
                          title={<Text style={{ color: 'red' }}>No templates available</Text>}
                        >
                          <TemplatePlanCTA data-template-dropdown-visible={templateDropdownVisible}>
                            Create Using Templates
                            <DropdownIcon icon={faAngleDown} size="lg" />
                          </TemplatePlanCTA>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {vehicle.inventoryType !== NEW && (
                    <CommonLinkButton
                      disabled={isSaving}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:
                          tasks.items.length === 0 && taskGroups.length === 0 && tasksWithTransientTasks.length === 0
                            ? '0'
                            : '-21px 0 10px',
                        padding: '12px 50px',
                        position: 'relative',
                        zIndex: '100',
                        alignItems: 'center'
                      }}
                      onClick={() => {
                        if (taskGroups.length === 0) {
                          setTemplatePlanCreatedFrom(null); // clear out
                        }
                        setFormProps({
                          vehicleId: vehicle.id,
                          count: taskGroups.length > 0 ? last(last(taskGroups).tasks).sequence : 0,
                          onCreate: onTaskChange('add')
                        });
                      }}
                    >
                      {createTaskLabel}
                    </CommonLinkButton>
                  )}
                </>
              )}
          </TaskCTAContainer>
        </StyledContentContainer>
        {!isHQ && (
          <StyledFooterContainer fullWidth={showCancelButton}>
            {(hasEditTasks || enableCompleteForInternalTech) && showDiscardButton ? (
              <CommonRedButton onClick={() => closeDrawer()} style={{ marginRight: '24px' }}>
                Discard Changes
              </CommonRedButton>
            ) : (
              <>
                {showCancelButton && (
                  <CommonLinkButton
                    disabled={isSaving}
                    onClick={() => handleCanClose()}
                    style={{ marginRight: '24px' }}
                  >
                    Cancel
                  </CommonLinkButton>
                )}
              </>
            )}
            {!missingAssignees ? (
              (hasEditTasks || enableCompleteForInternalTech) && (
                <CommonRedButton
                  loading={isSaving}
                  disabled={disableSaveButton}
                  onClick={onSaveWithGroups}
                  style={{ marginRight: '24px' }}
                >
                  {getSaveButtonLabel()}
                </CommonRedButton>
              )
            ) : (
              <Tooltip
                placement="left"
                overlayClassName="recon-plan-need-assignee-tooltip"
                title={<Text style={{ color: 'red' }}>All tasks must be assigned before the plan can start.</Text>}
              >
                {hasEditTasks && (
                  <CommonRedButton
                    loading={isSaving}
                    disabled={disableSaveButton}
                    onClick={() => {}}
                    style={{ marginRight: '24px' }}
                  >
                    {getSaveButtonLabel()}
                  </CommonRedButton>
                )}
              </Tooltip>
            )}
          </StyledFooterContainer>
        )}
      </div>
    );
  }
);

//#region Styled Components
const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 73px;
  padding: 0px 16px;
`;
const StyledFooterContainer = styled(CommonFooterContainer)`
  justify-content: flex-end;
  height: 73px;
  position: absolute;
  bottom: 0;
  z-index: 150;
  width: ${({ fullWidth }) =>
    fullWidth
      ? '100%'
      : '49.9%'}; /* The 49.9% is to give it a little gap so that it doesn't cover up the right border on the VDP/TDP */
`;
const StyledNoPlanText = styled(Text)`
  && {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme, newStyles }) => (newStyles ? theme.colors.darkGray : theme.colors.gray)};
    display: block;
    margin-bottom: 16px;
  }
`;
const StyledNoPlanDescriptionText = styled(Text)`
  && {
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.darkGray};
    display: block;
    margin-bottom: 16px;
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  margin-left: 16px;
`;

const TemplatePlanCTA = styled(Button).attrs({
  type: 'danger'
})`
  .ant-btn& {
    pointer-events: all;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    border-radius: 4px;
    border: none;
    outline: none;
    margin: 16px auto 8px;
    cursor: pointer;
  }
  ${elementRotate('fa-angle-down', 'data-template-dropdown-visible', 180, 0.3)}
`;

const TaskCTAContainer = styled.div.attrs({
  className: 'center-content'
})`
  flex-direction: column;
`;

const StyledToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-btn-link {
    padding-right: 8px;
  }
`;
//#endregion

export default withLDConsumer()(ReconPlan);
